import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import TransitionWrapper from '../components/TransitionWrapper';

export default function NotFound() {
  return (
    <TransitionWrapper>
      <div className="flex items-center justify-center min-h-[70vh] px-4 py-12 bg-white dark:bg-secondary-900">
        <div className="max-w-md text-center">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center">
              <h1 className="text-9xl font-extrabold text-primary-600 dark:text-primary-400">404</h1>
              <div className="h-1 w-16 bg-accent-600 mx-auto my-6"></div>
              <h2 className="text-3xl font-bold tracking-tight text-secondary-900 dark:text-white md:text-4xl font-display">
                Page Not Found
              </h2>
              <p className="mt-4 text-lg text-secondary-600 dark:text-secondary-300">
                Sorry, we couldn't find the page you're looking for.
              </p>
              <div className="flex flex-wrap justify-center gap-4 mt-8">
                <Link
                  to="/"
                  className="btn btn-primary"
                >
                  Go to Home
                </Link>
                <Link
                  to="/blog"
                  className="btn btn-secondary"
                >
                  Browse Articles
                </Link>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </TransitionWrapper>
  );
}