import { Link } from 'react-router-dom';
import { 
  EnvelopeIcon, 
  ShieldCheckIcon, 
  LockClosedIcon, 
  CheckBadgeIcon
} from '@heroicons/react/24/outline';

const navigation = {
  main: [
    { name: 'Home', href: '/' },
    { name: 'Blog', href: '/blog' },
    { name: 'Email Security', href: '/blog?category=email-security' },
    { name: 'BIMI', href: '/blog?category=bimi' },
    { name: 'DMARC', href: '/blog?category=dmarc' },
    { name: 'Contact', href: 'https://inboxmark.com/contact' },
    { name: 'Email Authentication Tools', href: 'https://brandymail.com', external: true },
  ],
  solutions: [
    { name: 'Email Authentication', href: '/blog?category=email-authentication' },
    { name: 'Phishing Prevention', href: '/blog?category=phishing-prevention' },
    { name: 'Brand Protection', href: '/blog?category=brand-protection' },
    { name: 'DMARC Implementation', href: '/blog?category=dmarc-implementation' },
    { name: 'Verified Mark Certificates', href: '/blog?category=vmc' },
    { name: 'Enterprise Email Solutions', href: 'https://brandymail.com/solutions', external: true },
  ],
  resources: [
    { name: 'Guides', href: '/blog?tag=guide' },
    { name: 'Whitepapers', href: '/blog?tag=whitepaper' },
    { name: 'Case Studies', href: '/blog?tag=case-study' },
    { name: 'Security Tips', href: '/blog?tag=security-tips' },
    { name: 'Expert Insights', href: '/blog?tag=expert-insights' },
  ],
  social: [
    {
      name: 'Twitter',
      href: '#',
      icon: () => (
        <svg fill="currentColor" viewBox="0 0 24 24" className="w-6 h-6">
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'LinkedIn',
      href: '#',
      icon: () => (
        <svg fill="currentColor" viewBox="0 0 24 24" className="w-6 h-6">
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
        </svg>
      ),
    },
  ],
};

const features = [
  {
    name: 'Email Security',
    description: 'Protect your business from email-based threats and phishing attacks.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Brand Protection',
    description: 'Safeguard your brand identity and reputation in the digital space.',
    icon: CheckBadgeIcon,
  },
  {
    name: 'DMARC Compliance',
    description: 'Achieve and maintain email authentication standards.',
    icon: LockClosedIcon,
  },
  {
    name: 'Expert Guidance',
    description: 'Get insights from industry professionals on email security best practices.',
    icon: EnvelopeIcon,
  },
];

export default function Footer() {
  return (
    <footer className="bg-white dark:bg-secondary-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="container py-12 md:py-16">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <div className="flex items-center">
              <span className="text-2xl font-bold tracking-tight text-primary-800 dark:text-white font-display">
                Inbox<span className="text-accent-600">Mark</span>
              </span>
            </div>
            <p className="text-base text-secondary-600 dark:text-secondary-300">
              Secure your email communications, elevate your brand, and protect against phishing with industry-leading expertise.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-secondary-500 hover:text-secondary-700 dark:text-secondary-400 dark:hover:text-secondary-300">
                  <span className="sr-only">{item.name}</span>
                  <item.icon />
                </a>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 mt-12 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-secondary-500 uppercase dark:text-secondary-300">
                  Solutions
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      {item.external ? (
                        <a 
                          href={item.href} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-base text-secondary-600 hover:text-primary-600 dark:text-secondary-400 dark:hover:text-primary-400 flex items-center"
                        >
                          {item.name}
                          <svg xmlns="http://www.w3.org/2000/svg" className="w-3.5 h-3.5 ml-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd" />
                          </svg>
                        </a>
                      ) : (
                        <Link to={item.href} className="text-base text-secondary-600 hover:text-primary-600 dark:text-secondary-400 dark:hover:text-primary-400">
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-secondary-500 uppercase dark:text-secondary-300">
                  Resources
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-base text-secondary-600 hover:text-primary-600 dark:text-secondary-400 dark:hover:text-primary-400">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <h3 className="text-sm font-semibold tracking-wider text-secondary-500 uppercase dark:text-secondary-300">
                Stay up to date
              </h3>
              <p className="mt-4 text-base text-secondary-600 dark:text-secondary-400">
                Subscribe to our newsletter for the latest email security insights.
              </p>
              <form className="mt-4">
                <div className="flex">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="w-full min-w-0 px-4 py-2 text-base text-secondary-900 placeholder-secondary-500 bg-white border border-secondary-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-secondary-800 dark:text-white dark:placeholder-secondary-400 dark:border-secondary-700"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="flex items-center justify-center px-4 py-2 text-base font-medium text-white bg-primary-600 border border-transparent rounded-r-md shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
              
              <div className="mt-6">
                <h3 className="text-sm font-semibold tracking-wider text-secondary-500 uppercase dark:text-secondary-300">
                  Why InboxMark
                </h3>
                <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
                  {features.map((feature) => (
                    <div key={feature.name} className="flex items-start">
                      <div className="flex-shrink-0">
                        <feature.icon className="w-5 h-5 text-primary-600 dark:text-primary-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h4 className="text-sm font-medium text-secondary-900 dark:text-white">{feature.name}</h4>
                        <p className="mt-1 text-xs text-secondary-600 dark:text-secondary-400">{feature.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8 mt-12 border-t border-secondary-200 dark:border-secondary-700">
          <p className="text-sm text-secondary-500 dark:text-secondary-400">
            &copy; {new Date().getFullYear()} InboxMark. All rights reserved.
          </p>
          <div className="flex flex-wrap mt-4 space-x-6">
            {navigation.main.map((item) => (
              item.external ? (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-secondary-600 hover:text-primary-600 dark:text-secondary-400 dark:hover:text-primary-400"
                >
                  {item.name} ↗
                </a>
              ) : (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-sm text-secondary-600 hover:text-primary-600 dark:text-secondary-400 dark:hover:text-primary-400"
                >
                  {item.name}
                </Link>
              )
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}