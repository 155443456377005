import { ReactNode } from 'react';
import { motion, Variants } from 'framer-motion';
import { pageTransition } from './animations';

interface TransitionWrapperProps {
  children: ReactNode;
  className?: string;
  delay?: number;
}

export default function TransitionWrapper({
  children,
  className = '',
  delay = 0,
}: TransitionWrapperProps) {
  const variants: Variants = {
    initial: pageTransition.initial,
    animate: {
      ...pageTransition.animate,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
        when: 'beforeChildren',
        staggerChildren: 0.1,
        delay,
      },
    },
    exit: pageTransition.exit,
  };

  return (
    <motion.div
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      className={className}
    >
      {children}
    </motion.div>
  );
}
