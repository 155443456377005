import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { staggerContainer, itemFadeIn } from './animations';

interface SectionProps {
  id?: string;
  title?: string;
  subtitle?: string;
  children: ReactNode;
  className?: string;
  withContainer?: boolean;
  dark?: boolean;
}

export default function Section({
  id,
  title,
  subtitle,
  children,
  className = '',
  withContainer = true,
  dark = false,
}: SectionProps) {
  // Set up intersection observer
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section
      ref={ref}
      id={id}
      className={`py-12 md:py-16 ${
        dark ? 'bg-primary-900 text-white' : 'bg-white dark:bg-secondary-900'
      } ${className}`}
    >
      <div className={withContainer ? 'container' : ''}>
        {(title || subtitle) && (
          <motion.div
            className="max-w-3xl mx-auto mb-12 text-center"
            variants={staggerContainer}
            initial="initial"
            animate={inView ? 'animate' : 'initial'}
          >
            {title && (
              <motion.h2
                className={`text-3xl font-bold tracking-tight md:text-4xl font-display ${
                  dark ? 'text-white' : 'text-secondary-900 dark:text-white'
                }`}
                variants={itemFadeIn}
              >
                {title}
              </motion.h2>
            )}
            
            {subtitle && (
              <motion.p
                className={`mt-4 text-lg ${
                  dark
                    ? 'text-primary-100'
                    : 'text-secondary-600 dark:text-secondary-300'
                }`}
                variants={itemFadeIn}
              >
                {subtitle}
              </motion.p>
            )}
          </motion.div>
        )}
        
        <motion.div
          variants={staggerContainer}
          initial="initial"
          animate={inView ? 'animate' : 'initial'}
        >
          {children}
        </motion.div>
      </div>
    </section>
  );
}