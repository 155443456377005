import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import TransitionWrapper from '../components/TransitionWrapper';
import { usePosts, useCategories, useTags } from '../hooks/useWPData';
import PostCard from '../components/PostCard';
import LoadingSpinner from '../components/LoadingSpinner';
import Section from '../components/Section';
import { itemFadeIn } from '../components/animations';

export default function Blog() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryParam = queryParams.get('category');
  const tagParam = queryParams.get('tag');

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const [filteredCategoryId, setFilteredCategoryId] = useState<number | null>(null);
  const [filteredTagId, setFilteredTagId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { posts, isLoading } = usePosts(currentPage, postsPerPage);
  const { categories } = useCategories();
  const { tags } = useTags();

  // Filtered posts
  const filteredPosts = posts.filter((post) => {
    const matchesCategory =
      filteredCategoryId === null || post.categories.includes(filteredCategoryId);
    const matchesTag = filteredTagId === null || post.tags.includes(filteredTagId);
    const matchesSearch =
      searchTerm === '' ||
      post.title.rendered.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.excerpt.rendered.toLowerCase().includes(searchTerm.toLowerCase());

    return matchesCategory && matchesTag && matchesSearch;
  });

  // Effect for URL params
  useEffect(() => {
    if (categoryParam && categories.length > 0) {
      const category = categories.find(
        (cat) =>
          cat.slug === categoryParam || cat.name.toLowerCase() === categoryParam.toLowerCase()
      );
      if (category) {
        setFilteredCategoryId(category.id);
      }
    }

    if (tagParam && tags.length > 0) {
      const tag = tags.find(
        (t) => t.slug === tagParam || t.name.toLowerCase() === tagParam.toLowerCase()
      );
      if (tag) {
        setFilteredTagId(tag.id);
      }
    }
  }, [categoryParam, tagParam, categories, tags]);

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredCategoryId, filteredTagId, searchTerm]);

  // Handle filter changes
  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setFilteredCategoryId(value === '' ? null : parseInt(value));
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setFilteredTagId(value === '' ? null : parseInt(value));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClearFilters = () => {
    setFilteredCategoryId(null);
    setFilteredTagId(null);
    setSearchTerm('');
  };

  return (
    <TransitionWrapper>
      {/* Hero Section */}
      <section className="pt-32 pb-16 bg-primary-900 md:pt-40 md:pb-24">
        <div className="container">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl font-display">
              Email Security Insights
            </h1>
            <p className="mt-6 text-xl text-primary-100">
              Expert articles, guides, and resources on email security, brand protection, and
              phishing prevention.
            </p>
          </div>
        </div>
      </section>

      {/* Filter Section */}
      <Section className="py-8 border-b border-secondary-200 dark:border-secondary-700">
        <div className="grid gap-4 md:grid-cols-4 md:gap-6">
          <div className="md:col-span-2">
            <label
              htmlFor="search"
              className="block mb-2 text-sm font-medium text-secondary-700 dark:text-secondary-300"
            >
              Search Articles
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-secondary-500 dark:text-secondary-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="block w-full p-2.5 pl-10 text-sm text-secondary-900 bg-white border border-secondary-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-secondary-800 dark:border-secondary-600 dark:placeholder-secondary-400 dark:text-white"
                placeholder="Search for articles..."
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="category-filter"
              className="block mb-2 text-sm font-medium text-secondary-700 dark:text-secondary-300"
            >
              Filter by Category
            </label>
            <select
              id="category-filter"
              value={filteredCategoryId || ''}
              onChange={handleCategoryChange}
              className="block w-full p-2.5 text-sm text-secondary-900 bg-white border border-secondary-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-secondary-800 dark:border-secondary-600 dark:text-white"
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name} ({category.count})
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="tag-filter"
              className="block mb-2 text-sm font-medium text-secondary-700 dark:text-secondary-300"
            >
              Filter by Tag
            </label>
            <select
              id="tag-filter"
              value={filteredTagId || ''}
              onChange={handleTagChange}
              className="block w-full p-2.5 text-sm text-secondary-900 bg-white border border-secondary-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-secondary-800 dark:border-secondary-600 dark:text-white"
            >
              <option value="">All Tags</option>
              {tags.map((tag) => (
                <option key={tag.id} value={tag.id}>
                  {tag.name} ({tag.count})
                </option>
              ))}
            </select>
          </div>

          {(filteredCategoryId !== null || filteredTagId !== null || searchTerm !== '') && (
            <div className="flex items-end md:col-span-4">
              <button
                onClick={handleClearFilters}
                className="px-4 py-2.5 text-sm font-medium text-primary-600 bg-primary-50 rounded-lg hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-primary-900/30 dark:text-primary-400 dark:hover:bg-primary-900/50"
              >
                Clear Filters
              </button>
            </div>
          )}
        </div>
      </Section>

      {/* Blog Posts */}
      <Section>
        {isLoading ? (
          <LoadingSpinner size="lg" className="py-12" />
        ) : filteredPosts.length > 0 ? (
          <>
            <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {filteredPosts.map((post) => (
                <motion.div key={post.id} variants={itemFadeIn}>
                  <PostCard post={post} />
                </motion.div>
              ))}
            </div>

            {/* Pagination */}
            <div className="flex justify-center mt-12">
              <nav className="inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className="px-4 py-2 text-sm font-medium text-secondary-500 bg-white border border-secondary-300 rounded-l-md hover:bg-secondary-50 disabled:opacity-50 disabled:cursor-not-allowed dark:bg-secondary-800 dark:border-secondary-600 dark:text-secondary-300 dark:hover:bg-secondary-700"
                >
                  Previous
                </button>
                <span className="px-4 py-2 text-sm font-medium text-primary-600 bg-primary-50 border border-secondary-300 dark:bg-primary-900/30 dark:border-secondary-600 dark:text-primary-400">
                  Page {currentPage}
                </span>
                <button
                  onClick={() => setCurrentPage((prev) => prev + 1)}
                  disabled={filteredPosts.length < postsPerPage}
                  className="px-4 py-2 text-sm font-medium text-secondary-500 bg-white border border-secondary-300 rounded-r-md hover:bg-secondary-50 disabled:opacity-50 disabled:cursor-not-allowed dark:bg-secondary-800 dark:border-secondary-600 dark:text-secondary-300 dark:hover:bg-secondary-700"
                >
                  Next
                </button>
              </nav>
            </div>
          </>
        ) : (
          <div className="py-12 text-center">
            <h3 className="text-xl font-semibold text-secondary-900 dark:text-white">
              No articles found
            </h3>
            <p className="mt-2 text-secondary-600 dark:text-secondary-400">
              Try adjusting your search or filter criteria
            </p>
            <button
              onClick={handleClearFilters}
              className="px-4 py-2 mt-4 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Clear All Filters
            </button>
          </div>
        )}
      </Section>

      {/* CTA Section */}
      <Section
        className="bg-secondary-50 dark:bg-secondary-800"
        title="Advanced Email Security"
        subtitle="Protect your organization with enterprise-grade email authentication tools."
      >
        <div className="max-w-4xl mx-auto">
          <div className="grid gap-8 md:grid-cols-2">
            <div>
              <form className="mt-4">
                <h3 className="text-xl font-semibold text-secondary-900 dark:text-white mb-4">
                  Stay Updated
                </h3>
                <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0">
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="w-full px-4 py-3 text-base text-secondary-900 placeholder-secondary-500 bg-white border border-secondary-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500 sm:rounded-r-none dark:bg-secondary-700 dark:text-white dark:placeholder-secondary-400 dark:border-secondary-600"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="flex items-center justify-center px-6 py-3 text-base font-medium text-white bg-primary-600 border border-transparent rounded-md shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:rounded-l-none"
                  >
                    Subscribe
                  </button>
                </div>
                <p className="mt-3 text-sm text-secondary-500 dark:text-secondary-400">
                  We respect your privacy. Unsubscribe at any time.
                </p>
              </form>
            </div>

            <div className="bg-white dark:bg-secondary-900 p-6 rounded-lg shadow-md border border-secondary-200 dark:border-secondary-700">
              <h3 className="text-xl font-semibold text-secondary-900 dark:text-white mb-4">
                Brandymail Enterprise Tools
              </h3>
              <p className="text-secondary-700 dark:text-secondary-300 mb-4">
                Looking for comprehensive email security solutions? Brandymail provides
                enterprise-grade tools for DMARC compliance, BIMI implementation, and phishing
                prevention.
              </p>
              <ul className="space-y-2 mb-5">
                <li className="flex items-start">
                  <svg
                    className="w-5 h-5 text-primary-600 mt-0.5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  <span className="text-secondary-700 dark:text-secondary-300">
                    DMARC Monitoring & Enforcement
                  </span>
                </li>
                <li className="flex items-start">
                  <svg
                    className="w-5 h-5 text-primary-600 mt-0.5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  <span className="text-secondary-700 dark:text-secondary-300">
                    BIMI & VMC Implementation
                  </span>
                </li>
                <li className="flex items-start">
                  <svg
                    className="w-5 h-5 text-primary-600 mt-0.5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  <span className="text-secondary-700 dark:text-secondary-300">
                    Advanced Phishing Protection
                  </span>
                </li>
              </ul>
              <a
                href="https://brandymail.com/enterprise?ref=inboxmark-blog"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700"
              >
                Explore Enterprise Solutions
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 ml-1.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </Section>
    </TransitionWrapper>
  );
}
