import { useState, useEffect } from 'react';

type Theme = 'dark' | 'light';

export default function useDarkMode() {
  // Check for theme in localStorage or use system preference
  const getInitialTheme = (): Theme => {
    // Check if we're in the browser
    if (typeof window === 'undefined') return 'light';

    // Try to get theme from localStorage
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme === 'dark' || storedTheme === 'light') {
      return storedTheme;
    }

    // Fall back to system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  };

  const [theme, setTheme] = useState<Theme>(getInitialTheme);

  // Update theme in DOM and localStorage when state changes
  useEffect(() => {
    const root = document.documentElement;

    // Remove both classes to ensure clean state
    root.classList.remove('dark', 'light');

    // Add current theme class
    root.classList.add(theme);

    // Store in localStorage
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Toggle between themes
  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return { theme, toggleTheme };
}