import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Disclosure, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import useDarkMode from '../hooks/useDarkMode';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Blog', href: '/blog' },
  { name: 'Email Security', href: '/blog?category=email-security' },
  { name: 'BIMI', href: '/blog?category=bimi' },
  { name: 'DMARC', href: '/blog?category=dmarc' },
];

export default function Navbar() {
  const { theme, toggleTheme } = useDarkMode();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  // Change navbar style on scroll
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isCurrentPath = (path: string) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  return (
    <Disclosure
      as="nav"
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        scrolled 
          ? 'bg-white/90 dark:bg-secondary-950/90 backdrop-blur-md shadow-lg py-2'
          : 'bg-white dark:bg-secondary-950 py-4'
      }`}
    >
      {({ open }) => (
        <>
          <div className="container">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-secondary-500 rounded-md hover:bg-secondary-100 hover:text-secondary-900 dark:text-secondary-300 dark:hover:bg-secondary-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                  {open ? (
                    <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
                <div className="flex items-center flex-shrink-0">
                  <Link to="/" className="flex items-center">
                    <motion.div 
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="flex items-center"
                    >
                      <span className="text-xl font-bold tracking-tight text-primary-800 dark:text-white font-display">
                        Inbox<span className="text-accent-600">Mark</span>
                      </span>
                    </motion.div>
                  </Link>
                </div>
                
                <div className="hidden sm:ml-6 sm:flex sm:space-x-4 sm:items-center">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={`${
                        isCurrentPath(item.href)
                          ? 'text-primary-600 dark:text-primary-400 font-semibold'
                          : 'text-secondary-600 dark:text-secondary-300 hover:text-primary-600 dark:hover:text-primary-400'
                      } px-3 py-2 text-sm font-medium relative`}
                    >
                      {item.name}
                      {isCurrentPath(item.href) && (
                        <motion.div
                          layoutId="navbar-indicator"
                          className="absolute bottom-0 left-0 w-full h-0.5 bg-primary-600 dark:bg-primary-400"
                          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                        />
                      )}
                    </Link>
                  ))}
                </div>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Dark mode toggle */}
                <button
                  type="button"
                  onClick={toggleTheme}
                  className="p-2 text-secondary-500 rounded-full hover:bg-secondary-100 dark:text-secondary-300 dark:hover:bg-secondary-800 focus:outline-none focus:ring-2 focus:ring-primary-500"
                >
                  {theme === 'dark' ? (
                    <SunIcon className="w-5 h-5" aria-hidden="true" />
                  ) : (
                    <MoonIcon className="w-5 h-5" aria-hidden="true" />
                  )}
                </button>
                
                {/* CTA Button (visible on desktop) */}
                <div className="hidden ml-4 sm:block">
                  <motion.a
                    href="https://inboxmark.com/contact"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="btn btn-primary shadow-sharp-sm"
                  >
                    Get Started
                  </motion.a>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <Transition
            show={open}
            enter="transition duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition duration-150 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as={Link}
                    to={item.href}
                    className={`${
                      isCurrentPath(item.href)
                        ? 'text-primary-600 dark:text-primary-400 bg-secondary-50 dark:bg-secondary-800'
                        : 'text-secondary-600 dark:text-secondary-300 hover:text-primary-600 dark:hover:text-primary-400'
                    } block px-3 py-2 text-base font-medium rounded-md`}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
                <div className="pt-2">
                  <Disclosure.Button
                    as="a"
                    href="https://inboxmark.com/contact"
                    className="block w-full px-4 py-2 text-center text-white bg-primary-600 rounded-md shadow-sharp-sm btn"
                  >
                    Get Started
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}