import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { Post } from '../services/api';
import { cardHover } from './animations';

interface PostCardProps {
  post: Post;
  featured?: boolean;
}

export default function PostCard({ post, featured = false }: PostCardProps) {
  // Extract featured image URL
  const featuredImageUrl = post._embedded?.['wp:featuredmedia']?.[0]?.source_url || '/placeholder.jpg';
  const altText = post._embedded?.['wp:featuredmedia']?.[0]?.alt_text || post.title.rendered;
  
  // Format date
  const formattedDate = format(new Date(post.date), 'MMMM d, yyyy');
  
  // Extract excerpt and clean HTML tags
  const excerpt = post.excerpt.rendered
    .replace(/<[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .substring(0, featured ? 160 : 120)
    .trim() + '...';

  return (
    <motion.div
      className={`overflow-hidden transition-all duration-300 bg-white rounded-lg shadow-lg dark:bg-secondary-800 dark:border dark:border-secondary-700 ${
        featured ? 'md:col-span-2 md:grid md:grid-cols-2 md:gap-4' : ''
      }`}
      variants={cardHover}
      initial="initial"
      whileHover="hover"
      whileTap="tap"
    >
      <Link to={`/blog/${post.slug}`} className="block">
        <div className={`overflow-hidden ${featured ? 'md:h-full' : 'h-48'}`}>
          <img
            src={featuredImageUrl}
            alt={altText}
            className="object-cover w-full h-full transition-transform duration-300 hover:scale-105"
          />
        </div>
      </Link>
      
      <div className={`p-6 ${featured ? 'space-y-4' : 'space-y-2'}`}>
        {post.acf?.security_tip && (
          <div className="inline-block px-3 py-1 mb-2 text-xs font-medium text-accent-700 bg-accent-50 rounded-full dark:bg-accent-900/30 dark:text-accent-300">
            Security Tip
          </div>
        )}
        
        <Link to={`/blog/${post.slug}`} className="block">
          <h3 className={`font-bold tracking-tight text-secondary-900 hover:text-primary-600 dark:text-white dark:hover:text-primary-400 ${
            featured ? 'text-2xl' : 'text-xl'
          }`}>
            {post.title.rendered}
          </h3>
        </Link>
        
        <p className="text-secondary-600 dark:text-secondary-300">
          {excerpt}
        </p>
        
        <div className="flex items-center pt-2 mt-2 text-sm text-secondary-500 border-t border-secondary-100 dark:border-secondary-700 dark:text-secondary-400">
          <time dateTime={post.date}>{formattedDate}</time>
          
          {post.acf?.expert_insight && (
            <span className="flex items-center ml-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-1 text-primary-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
              </svg>
              Expert Insight
            </span>
          )}
        </div>
        
        {featured && (
          <Link 
            to={`/blog/${post.slug}`}
            className="inline-flex items-center mt-4 text-sm font-medium text-primary-600 dark:text-primary-400 hover:text-primary-700 dark:hover:text-primary-300"
          >
            Read full article
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </Link>
        )}
      </div>
    </motion.div>
  );
}